import React, { useEffect, useState} from 'react';

import { Auth } from 'aws-amplify';
import { Redirect } from 'react-router';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import './dashboard.css';
import './sidebar.css';

function Dashboard() {

    const [ redirectLogin, setRedirectLogin ] = useState(false);
    const [ redirectUrl, setRedirectUrl ] = useState("");

    useEffect(() => {
        async function load() {

            try {
                const user = await Auth.currentSession();
            } catch (e) {
                console.log(e);
                // User is not logged in, redirect to signin
                // setRedirectUrl("/");
                // setRedirectLogin(true);
            }
        }
        load();
    }, []);

    function redirectSidebar(type: string) {
        switch(type) {
            case "companies": {
                setRedirectUrl("/companies");
                setRedirectLogin(true);
            }
            break;
        }
    }

    if (redirectLogin) {
        return (
            <Redirect to={redirectUrl} />
        )
    } else {
        return (
            <div className="dashboard">
                <div className="sidebar">
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button  onClick={() => { redirectSidebar("companies"); }} >
                        <ListItemText primary="Companies" />
                    </ListItem>
                    <ListItem button  onClick={() => { redirectSidebar("users"); }} >
                        <ListItemText primary="Users" />
                    </ListItem>
                </List>
                </div>
                <div className="content">
                    <h2>Main Event Dashboard</h2>
                </div>
            </div>
        );
    }
}

export default Dashboard;