import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { gql } from '@apollo/client';

import Configuration from './libraries/configuration';
import Events from './events';

import './companies.css';
import './sidebar.css';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress, Divider } from '@material-ui/core';

function Companies() {

    let client;

    const [ loaded, setLoaded ] = useState(false);
    const [ loadingBar, setLoadingBar ] = useState(true);
    
    const [ companyList, setCompanyList ] = useState([]);
    const [ view, setView ] = useState("list");

    const [ getCompanyId, setCompanyId ] = useState("");

    // Create form
    const [ companyName, setCompanyName ] = useState("");
    const [ companyAddress1, setCompanyAddress1 ] = useState("");
    const [ companyAddress2, setCompanyAddress2 ] = useState("");
    const [ companyState, setCompanyState ] = useState("");
    const [ companyTownCity, setCompanyTownCity ] = useState("");
    const [ companyEIN, setCompanyEIN ] = useState("");
    const [ companyZip, setCompanyZip ] = useState("");

    // Contact
    const [ contactId, setContactId ] = useState("");
    const [ contactName, setContactName ] = useState("");
    const [ contactPhone, setContactPhone ] = useState("");
    const [ contactFax, setContactFax ] = useState("");
    const [ contactEmail, setContactEmail ] = useState("");

    // Events for a company
    const [ eventsCompanyId, setEventsCompanyId ] = useState("");
    const [ eventsCompanyName, setEventsCompanyName ] = useState("");

    const [ getEventsView, setEventsView ] = useState("");
    
    useEffect(() => {
        async function load() {
            try {
                await retrieveCompanyList();
                setLoaded(true);
                setLoadingBar(false);
            } catch (e) {
                console.log(e);
                // User is not logged in, redirect to signin
                // setRedirectUrl("/");
                // setRedirectLogin(true);
            }
        }
        if (!loaded) {
            load();
        }
    }, []);

    async function retrieveCompanyList() {
        const user = await Auth.currentSession();
        const queryName = "getCompanyList";
        const QUERY = gql`
            query ${queryName}(
                $userId: String
            ) {
                ${queryName} (
                    userId: $userId
                ) {
                    companyId,
                    companyName,
                    companyEIN,
                    address1,
                    address2,
                    state,
                    townCity,
                    zip,
                    dateCreated,
                    dateModified,
                    deleted
                }
            }
        `;

        const response: any = await Configuration.GraphQLClient(user).query({
            variables: {
                "userId": user.getAccessToken().payload.sub
            },
            query: QUERY,
            fetchPolicy: "network-only"
        });
        setCompanyList(response.data[queryName]);
    }

    // type: "list" | "create"
    function changeView(type: string) {
        setView(type);
    }

    function backToEventList() {
        setView("events-list");
    }

    function backToCompanyList() {
        changeView("list");
    }

    function showCreateNewCompanyForm() {
        changeView("create");
    }

    // Create the company
    async function createNewCompany() {
        try {
            const user = await Auth.currentSession();
            const queryName = "createCompany";
            const QUERY = gql`
                mutation ${queryName}(
                    $userId: String,
                    $companyName: String,
                    $companyEIN: String,
                    $address1: String,
                    $address2: String,
                    $state: String,
                    $townCity: String,
                    $zip: String
                ) {
                    ${queryName} (
                        userId: $userId,
                        companyName: $companyName,
                        companyEIN: $companyEIN,
                        address1: $address1,
                        address2: $address2,
                        state: $state,
                        townCity: $townCity,
                        zip: $zip
                    )
                }
            `;

            const result = await Configuration.GraphQLClient(user).mutate({
                variables: {
                    "userId": user.getAccessToken().payload.sub,
                    companyName,
                    companyEIN,
                    address1: companyAddress1,
                    address2: companyAddress2,
                    state: companyState,
                    townCity: companyTownCity,
                    zip: companyZip
                },
                mutation: QUERY
            });
            console.log(result);
            await retrieveCompanyList();
            setView("list");
        } catch (e) {
            console.log(e);
            // User is not logged in, redirect to signin
            // setRedirectUrl("/");
            // setRedirectLogin(true);
        }
    }

    // Edit Company Button Press
    async function submitEditCompanyDetails() {
        try {
            const user = await Auth.currentSession();
            const queryName = "updateCompany";
            const QUERY = gql`
                mutation ${queryName}(
                    $userId: String,
                    $companyId: String,
                    $companyName: String,
                    $companyEIN: String,
                    $address1: String,
                    $address2: String,
                    $state: String,
                    $townCity: String,
                    $zip: String
                ) {
                    ${queryName} (
                        userId: $userId,
                        companyId: $companyId,
                        companyName: $companyName,
                        companyEIN: $companyEIN,
                        address1: $address1,
                        address2: $address2,
                        state: $state,
                        townCity: $townCity,
                        zip: $zip
                    )
                }
            `;

            const result = await Configuration.GraphQLClient(user).mutate({
                variables: {
                    "userId": user.getAccessToken().payload.sub,
                    companyId: getCompanyId,
                    companyName,
                    companyEIN,
                    address1: companyAddress1,
                    address2: companyAddress2,
                    state: companyState,
                    townCity: companyTownCity,
                    zip: companyZip
                },
                mutation: QUERY
            });
            await retrieveCompanyList();
            setView("list");
        } catch (e) {
            console.log(e);
            // User is not logged in, redirect to signin
            // setRedirectUrl("/");
            // setRedirectLogin(true);
        }
    }

    async function submitEditCompanyContactDetails() {
        try {
            const user = await Auth.currentSession();

            const queryName = "updateCompanyContact";
            const QUERY = gql`
                mutation ${queryName}(
                    $userId: String,
                    $companyId: String,
                    $contactId: String,
                    $contactName: String,
                    $contactEmail: String,
                    $contactPhone: String,
                    $contactFax: String
                ) {
                    ${queryName} (
                        userId: $userId,
                        companyId: $companyId,
                        contactId: $contactId,
                        contactName: $contactName,
                        contactEmail: $contactEmail,
                        contactPhone: $contactPhone,
                        contactFax: $contactFax
                    )
                }
            `;

            await Configuration.GraphQLClient(user).mutate({
                variables: {
                    "userId": user.getAccessToken().payload.sub,
                    companyId: getCompanyId,
                    contactId: contactId,
                    contactName: contactName,
                    contactEmail: contactEmail,
                    contactPhone: contactPhone,
                    contactFax: contactFax
                },
                mutation: QUERY
            });
            await retrieveCompanyList();
            setView("list");
        } catch (e) {
            console.log(e);
            // User is not logged in, redirect to signin
            // setRedirectUrl("/");
            // setRedirectLogin(true);
        }
    }

    function renderCompanyEvents(e: any, companyId: string, name: string) {
        setLoadingBar(true);
        setView("events");
        setEventsCompanyId(companyId);
        setEventsCompanyName(name);;
    }

    async function editCompany(companyId: string) {
        setLoadingBar(true);
        setView("edit");
        setCompanyId(companyId);

        const user = await Auth.currentSession();
        
        let queryName = "getCompanyDetails";
        let QUERY = gql`
            query ${queryName}(
                $userId: String,
                $companyId: String
            ) {
                ${queryName} (
                    userId: $userId,
                    companyId: $companyId
                ) {
                    companyId,
                    companyName,
                    companyEIN,
                    address1,
                    address2,
                    state,
                    townCity,
                    zip,
                    dateCreated,
                    dateModified,
                    deleted
                }
            }
        `;

        let response: any = await Configuration.GraphQLClient(user).query({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                companyId,
            },
            query: QUERY,
            fetchPolicy: "network-only"
        });

        const companyDetails = response.data[queryName];

        queryName = "getCompanyContactDetails";
        QUERY = gql`
            query ${queryName}(
                $userId: String,
                $companyId: String
            ) {
                ${queryName} (
                    userId: $userId,
                    companyId: $companyId
                ) {
                    companyId,
                    contactId,
                    primary,
                    contactName,
                    contactEmail,
                    contactPhone,
                    contactFax,
                    dateCreated,
                    dateModified,
                }
            }
        `;

        response = await Configuration.GraphQLClient(user).query({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                companyId,
            },
            query: QUERY,
            fetchPolicy: "network-only"
        });


        let contactDetails = response.data[queryName];
        if (contactDetails.length === 1) {
            contactDetails = contactDetails[0];
        }

        setCompanyName(companyDetails['companyName']);
        setCompanyAddress1(companyDetails['address1']);
        setCompanyAddress2(companyDetails['address2']);
        setCompanyState(companyDetails['state']);
        setCompanyTownCity(companyDetails['townCity']);
        setCompanyEIN(companyDetails['companyEIN']);
        setCompanyZip(companyDetails['zip']);
    
        // Contact
        setContactId(contactDetails['contactId']);
        setContactName(contactDetails['contactName']);
        setContactPhone(contactDetails['contactPhone']);
        setContactFax(contactDetails['contactFax']);
        setContactEmail(contactDetails['contactEmail']);

        setLoadingBar(false);
    }

    async function deleteCompany(companyId: string) {
        const user = await Auth.currentSession();
        const queryName = "deleteCompany";
        const QUERY = gql`
            mutation ${queryName}(
                $userId: String,
                $companyId: String
            ) {
                ${queryName} (
                    userId: $userId,
                    companyId: $companyId
                )
            }
        `;

        await Configuration.GraphQLClient(user).mutate({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                companyId,
            },
            mutation: QUERY
        });
        await retrieveCompanyList();
    }

    let loadingDiv = (<></>);
    if (loadingBar) {
        loadingDiv = (<CircularProgress />);
    }

    const footerDiv = (
        <div className="footer">
            <div className="container">
                <p>Copyright © 2020 <a href="http://maineventsound.com/" target="_blank">Main Event Sound</a></p>
            </div>
        </div>
    );

    switch(view) {
        case "events":
        case "events-list": {
            let header = (
                <div className="header">
                    <img src="/img/MESLLogo.png" />
                    <h2>Company Events</h2>
                    <div className="header-options">
                        {loadingDiv}
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className="create-new-company"
                            onClick={backToCompanyList}
                        >
                            Back to Company List
                        </Button>
                    </div>
                </div>
            );

            return (
                <>
                    <div className="companies">
                        <div className="content">
                            {header}
                            <div className="company-body">
                                <div className="company-events">
                                    <Events companyId={eventsCompanyId} companyName={eventsCompanyName} setLoadingBar={setLoadingBar} changeView={changeView} setEventsView={setEventsView} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {footerDiv}
                </>
            );
        }

        case "create": {
            return (
                <>
                    <div className="companies">
                        <div className="content">
                            <div className="header">
                                <img src="/img/MESLLogo.png" />
                                <h2>Create a new company</h2>
                                <div className="header-options">
                                    {loadingDiv}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        className="create-new-company"
                                        onClick={backToCompanyList}
                                    >
                                        Back to Company List
                                    </Button>
                                </div>
                            </div>
                            <div className="company-body">
                                <div className="company-list form">
                                    <form className="" noValidate>
                                        <h3>Company Details</h3>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="name"
                                            label="Company Name"
                                            id="companyname"
                                            autoFocus
                                            value={companyName}
                                            onChange={(e) => { setCompanyName(e.target.value); } }
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="ein"
                                            label="EIN"
                                            id="companyein"
                                            value={companyEIN}
                                            onChange={(e) => { setCompanyEIN(e.target.value); } }
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id="address1"
                                            label="Address 1"
                                            name="address1"
                                            value={companyAddress1}
                                            onChange={(e) => { setCompanyAddress1(e.target.value); } }
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="address2"
                                            label="Address 2"
                                            id="address2"
                                            value={companyAddress2}
                                            onChange={(e) => { setCompanyAddress2(e.target.value); } }
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="state"
                                            label="State"
                                            id="state"
                                            value={companyState}
                                            onChange={(e) => { setCompanyState(e.target.value); } }
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="townCity"
                                            label="Town / City"
                                            id="towncity"
                                            value={companyTownCity}
                                            onChange={(e) => { setCompanyTownCity(e.target.value); } }
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="zip"
                                            label="Zip"
                                            id="zip"
                                            value={companyZip}
                                            onChange={(e) => { setCompanyZip(e.target.value); } }
                                        />
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className="signin-button"
                                            onClick={createNewCompany}
                                        >
                                            Submit
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {footerDiv}
                </>
            );
        }

        case "edit": {
            return (
                <>
                    <div className="companies">
                        <div className="content">
                            <div className="header">
                                <img src="/img/MESLLogo.png" />
                                <h2>Edit a Company</h2>
                                <div className="header-options">
                                    {loadingDiv}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        className="create-new-company"
                                        onClick={backToCompanyList}
                                    >
                                        Back to Company List
                                    </Button>
                                </div>
                            </div>
                            <div className="company-body company-edit">
                                <div className="company-list details">
                                    <h3>Company Details</h3>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="name"
                                        label="Company Name"
                                        id="companyname"
                                        autoFocus
                                        value={companyName}
                                        onChange={(e) => { setCompanyName(e.target.value); } }
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="ein"
                                        label="EIN"
                                        id="companyein"
                                        value={companyEIN}
                                        onChange={(e) => { setCompanyEIN(e.target.value); } }
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="address1"
                                        label="Address 1"
                                        name="address1"
                                        value={companyAddress1}
                                        onChange={(e) => { setCompanyAddress1(e.target.value); } }
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="address2"
                                        label="Address 2"
                                        id="address2"
                                        value={companyAddress2}
                                        onChange={(e) => { setCompanyAddress2(e.target.value); } }
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="state"
                                        label="State"
                                        id="state"
                                        value={companyState}
                                        onChange={(e) => { setCompanyState(e.target.value); } }
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="townCity"
                                        label="Town / City"
                                        id="towncity"
                                        value={companyTownCity}
                                        onChange={(e) => { setCompanyTownCity(e.target.value); } }
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="zip"
                                        label="Zip"
                                        id="zip"
                                        value={companyZip}
                                        onChange={(e) => { setCompanyZip(e.target.value); } }
                                    />
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="signin-button"
                                        onClick={submitEditCompanyDetails}
                                    >
                                        Update Company Details
                                    </Button>
                                </div>
                                <div className="company-list contact">
                                    <h3>Contact</h3>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="name"
                                        label="Full Name"
                                        id="name"
                                        value={contactName}
                                        onChange={(e) => { setContactName(e.target.value); } }
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="contactemail"
                                        label="Email Address"
                                        id="contactemail"
                                        value={contactEmail}
                                        onChange={(e) => { setContactEmail(e.target.value); } }
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="phone"
                                        label="Phone"
                                        id="phone"
                                        value={contactPhone}
                                        onChange={(e) => { setContactPhone(e.target.value); } }
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="fax"
                                        label="Fax"
                                        id="fax"
                                        value={contactFax}
                                        onChange={(e) => { setContactFax(e.target.value); } }
                                    />
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className="signin-button"
                                        onClick={submitEditCompanyContactDetails}
                                    >
                                        Update Contact Details
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {footerDiv}
                </>
            );
        }

        case "list":
        default: {
            return (
                <>
                    <div className="companies">
                        <div className="content">
                            <div className="header">
                                <img src="/img/MESLLogo.png" />
                                <h2>All Companies List</h2>
                                <div className="header-options">
                                    {loadingDiv}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        className="create-new-company"
                                        onClick={showCreateNewCompanyForm}
                                    >
                                        Create new Company
                                    </Button>
                                </div>
                            </div>
                            <div className="company-body">
                                <div className="blur"></div>
                                <div className="company-list">
                                    <TableContainer component={Paper}>
                                        <Table className="companies-table" aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <TableCell>Company Name</TableCell>
                                                <TableCell align="left">EIN</TableCell>
                                                <TableCell align="left">Address 1</TableCell>
                                                <TableCell align="left">Address 2</TableCell>
                                                <TableCell align="left">State</TableCell>
                                                <TableCell align="left">Town / City</TableCell>
                                                <TableCell align="left">Zip Code</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    companyList.map((row: any) => {
                                                        return (
                                                            <TableRow key={row.companyId}>
                                                                <TableCell className="company-list-item" onClick={(e) => { renderCompanyEvents(e, row.companyId, row.companyName); }}>
                                                                    { row.companyName }
                                                                </TableCell>
                                                                <TableCell className="company-list-item" onClick={(e) => { renderCompanyEvents(e, row.companyId, row.companyName); }}>
                                                                    { row.companyEIN }
                                                                </TableCell>
                                                                <TableCell className="company-list-item" onClick={(e) => { renderCompanyEvents(e, row.companyId, row.companyName); }}>
                                                                    { row.address1 }
                                                                </TableCell>
                                                                <TableCell className="company-list-item" onClick={(e) => { renderCompanyEvents(e, row.companyId, row.companyName); }}>
                                                                    { row.address2 }
                                                                </TableCell>
                                                                <TableCell className="company-list-item" onClick={(e) => { renderCompanyEvents(e, row.companyId, row.companyName); }}>
                                                                    { row.state }
                                                                </TableCell>
                                                                <TableCell className="company-list-item" onClick={(e) => { renderCompanyEvents(e, row.companyId, row.companyName); }}>
                                                                    { row.townCity }
                                                                </TableCell>
                                                                <TableCell className="company-list-item" onClick={(e) => { renderCompanyEvents(e, row.companyId, row.companyName); }}>
                                                                    { row.zip }
                                                                </TableCell>
                                                                <TableCell onClick={() => { editCompany(row.companyId); }}>
                                                                    <FontAwesomeIcon className="icon-button" icon={faPencilAlt} />
                                                                </TableCell>
                                                                <TableCell onClick={() => { deleteCompany(row.companyId); }}>
                                                                    <FontAwesomeIcon className="icon-button" icon={faTrash} />
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                    {footerDiv}
                </>
            );
        }
    }
}
export default Companies;