import React, { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';

import Configuration from './libraries/configuration';

import DateFnsUtils from '@date-io/date-fns';
import { format, parseISO } from 'date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker
  } from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { gql } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faEnvelope, faPencilAlt, faTrash, faUserFriends } from '@fortawesome/free-solid-svg-icons';

import './events.css';
import { Divider, InputLabel, MenuItem, Select } from '@material-ui/core';
import { formatISO } from 'date-fns/esm';

import { CSVLink, CSVDownload } from 'react-csv';
import papa from "papaparse";

function Events(props) {

    const [ view, setView ] = useState("");

    const [ loaded, setLoaded ] = useState(false);
    const [ eventList, setEventList ] = useState([]);

    const [ getEventId, setEventId ] = useState("");
    const [ eventName, setEventName ] = useState("");
    const [ eventStreamURL, setEventStreamURL ] = useState("");
    
    const [ getEventIngestStreamURL, setEventIngestStreamURL ] = useState("");
    const [ getEventStreamMediaType, setEventStreamMediaType ] = useState("");
    const [ getEventIngestStreamKey, setEventIngestStreamKey ] = useState("");

    const [ eventPrice, setEventPrice ] = useState("");
    const [ eventCurrency, setEventCurrency ] = useState("");

    const [ clientURL, setClientURL ] = useState("");

    const [ emailSource, setEmailSource ] = useState("noreply@mainevent.com");
    const [ emailSupport, setEmailSupport ] = useState("support@mainevent.com");

    const [ eventStartDate, setEventStartDate] = useState<Date | null>(new Date());
    const [ eventEndDate, setEventEndDate ] = useState<Date | null>(new Date());

    // Users
    const [ getEventUserList, setEventUserList ] = useState<any>([]);
    const [ getCustomerView, setCustomerView ] = useState("");
    const [ getCustomerId, setCustomerId ] = useState("");
    const [ getCustomerName, setCustomerName ] = useState("");
    const [ getCustomerEmail, setCustomerEmail ] = useState("");
    const [ getCustomerPhone, setCustomerPhone ] = useState("");

    // Event User List Search
    const [ userSearchResults, setUserSearchResults ] = useState<any>([]);

    // Import Users
    const fileUploadRef: any = React.createRef();

    const eventRef = useRef(null);

    useEffect(() => {
        async function loadEvents() {
            await retrieveCompanyEvents();
        }

        if (!loaded) {
            loadEvents();
            setLoaded(true);
        }
    });

    async function retrieveCompanyEvents() {
        props.setLoadingBar(true);
        const user = await Auth.currentSession();
        const queryName = "getEventList";
        const QUERY = gql`
            query ${queryName}(
                $userId: String,
                $companyId: String
            ) {
                ${queryName} (
                    userId: $userId,
                    companyId: $companyId
                ) {
                    eventId,
                    companyId,
                    eventName,
                    eventStreamURL,
                    eventStreamMediaType,
                    eventIngestStreamURL,
                    eventIngestStreamKey,
                    eventCurrency,
                    eventPrice,
                    eventStartDate,
                    eventEndDate,
                    eventSourceEmail,
                    eventSupportEmail,
                    eventActive,
                    dateCreated,
                    dateModified,
                    deleted
                }
            }
        `;

        const response: any = await Configuration.GraphQLClient(user).query({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                companyId: props.companyId
            },
            query: QUERY,
            fetchPolicy: "network-only"
        });

        setEventList(response.data[queryName]);
        props.setLoadingBar(false);
    }

    function clearForm() {
        setEventId("");
        setEventName("");
        setEventStreamURL("");
        setEventStreamMediaType("");
        setEventIngestStreamURL("");
        setEventIngestStreamKey("");
        setEventPrice("");
        setEventCurrency("");
        setClientURL("");
        setEmailSource("");
        setEmailSupport("");
        setEventStartDate(new Date());
        setEventEndDate(new Date());
    }

    function clearUserForm() {
        setCustomerName("");
        setCustomerEmail("");
        setCustomerPhone("");
    }

    // Create a new Event
    async function createNewEvent() {
        props.setLoadingBar(true);
        const user = await Auth.currentSession();

        const queryName = "createEvent";
        const QUERY = gql`
            mutation ${queryName}(
                $userId: String, 
                $companyId: String,
                $eventName: String,
                $eventStreamURL: String,
                $eventStreamFileType: String,
                $eventIngestStreamURL: String,
                $eventIngestStreamKey: String,
                $eventCurrency: String, $eventPrice: Float,
                $clientURL: String,
                $eventSourceEmail: String, $eventSupportEmail: String,
                $eventStartDate: String, $eventEndDate: String, $eventActive: Boolean
            ) {
                ${queryName} (
                    userId: $userId,
                    companyId: $companyId,
                    eventName: $eventName,
                    eventStreamURL: $eventStreamURL,
                    eventStreamMediaType: $eventStreamFileType,
                    eventIngestStreamURL: $eventIngestStreamURL,
                    eventIngestStreamKey: $eventIngestStreamKey,
                    eventCurrency: $eventCurrency,
                    eventPrice: $eventPrice,
                    clientURL: $clientURL,
                    eventSourceEmail: $eventSourceEmail,
                    eventSupportEmail: $eventSupportEmail,
                    eventStartDate: $eventStartDate,
                    eventEndDate: $eventEndDate,
                    eventActive: $eventActive
                )
            }
        `;

        let eSD = "";
        let eED = "";
        if (eventStartDate !== null) {
            eSD = formatISO(eventStartDate);
        }

        if (eventEndDate !== null) {
            eED = formatISO(eventEndDate);
        }

        await Configuration.GraphQLClient(user).mutate({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                companyId: props.companyId,
                eventName,
                eventStreamURL,
                eventStreamMediaType: getEventStreamMediaType,
                eventIngestStreamURL: getEventIngestStreamURL,
                eventIngestStreamKey: getEventIngestStreamKey,
                eventPrice: parseFloat(eventPrice),
                eventCurrency,
                clientURL,
                eventSourceEmail: emailSource,
                eventSupportEmail: emailSupport,
                eventStartDate: eSD,
                eventEndDate: eED,
                eventActive: true
            },
            mutation: QUERY,
        });
        await retrieveCompanyEvents();
        clearForm();
        props.setLoadingBar(false);
    }

    function cancelEditEvent() {
        setView("");
        props.setEventsView("");
        clearForm();
    }

    async function editEvent() {
        props.setLoadingBar(true);
        const user = await Auth.currentSession();
        const queryName = "updateEvent";
        const QUERY = gql`
            mutation ${queryName}(
                $userId: String,
                $eventId: String,
                $companyId: String,
                $eventName: String,
                $eventStreamURL: String,
                $eventStreamMediaType: String,
                $eventIngestStreamURL: String,
                $eventIngestStreamKey: String,
                $eventCurrency: String, $eventPrice: Float,
                $clientURL: String,
                $eventSourceEmail: String, $eventSupportEmail: String,
                $eventStartDate: String, $eventEndDate: String, $eventActive: Boolean
            ) {
                ${queryName} (
                    userId: $userId,
                    eventId: $eventId,
                    companyId: $companyId,
                    eventName: $eventName,
                    eventStreamURL: $eventStreamURL,
                    eventStreamMediaType: $eventStreamMediaType,
                    eventIngestStreamURL: $eventIngestStreamURL,
                    eventIngestStreamKey: $eventIngestStreamKey,
                    eventCurrency: $eventCurrency,
                    eventPrice: $eventPrice,
                    clientURL: $clientURL,
                    eventSourceEmail: $eventSourceEmail,
                    eventSupportEmail: $eventSupportEmail,
                    eventStartDate: $eventStartDate,
                    eventEndDate: $eventEndDate,
                    eventActive: $eventActive
                )
            }
        `;

        await Configuration.GraphQLClient(user).mutate({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                companyId: props.companyId,
                eventId: getEventId,
                eventName,
                eventStreamURL,
                eventStreamMediaType: getEventStreamMediaType,
                eventIngestStreamURL: getEventIngestStreamURL,
                eventIngestStreamKey: getEventIngestStreamKey,
                eventPrice: parseFloat(eventPrice),
                eventCurrency,
                clientURL,
                eventSourceEmail: emailSource,
                eventSupportEmail: emailSupport,
                eventStartDate: eventStartDate,
                eventEndDate: eventEndDate,
                eventActive: true
            },
            mutation: QUERY,
        });
        await retrieveCompanyEvents();
        clearForm();
        props.setLoadingBar(false);
    }

    async function deleteEvent(companyId: string, eventId: string) {
        props.setLoadingBar(true);
        const user = await Auth.currentSession();
        const queryName = "deleteEvent";
        const QUERY = gql`
            mutation ${queryName}(
                $userId: String, 
                $companyId: String,
                $eventId: String
            ) {
                ${queryName} (
                    userId: $userId,
                    companyId: $companyId,
                    eventId: $eventId
                )
            }
        `;

        await Configuration.GraphQLClient(user).mutate({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                companyId,
                eventId,
            },
            mutation: QUERY,
        });
        await retrieveCompanyEvents();
        props.setLoadingBar(false);
    }

    // FORM
    async function editEventForm(companyId: string, eventId: string) {
        setEventId(eventId);
        props.setLoadingBar(true);
        const user = await Auth.currentSession();
        const queryName = "getEventDetails";
        const QUERY = gql`
            query ${queryName}(
                $userId: String,
                $eventId: String,
                $companyId: String
            ) {
                ${queryName} (
                    userId: $userId,
                    eventId: $eventId,
                    companyId: $companyId
                ) {
                    eventId,
                    companyId,
                    eventName,
                    eventStreamURL,
                    eventStreamMediaType,
                    eventIngestStreamURL,
                    eventIngestStreamKey,
                    eventCurrency,
                    eventPrice,
                    eventStartDate,
                    eventEndDate,
                    clientURL,
                    eventSourceEmail,
                    eventSupportEmail,
                    eventActive,
                    dateCreated,
                    dateModified,
                    deleted
                }
            }
        `;

        const response: any = await Configuration.GraphQLClient(user).query({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                eventId,
                companyId
            },
            query: QUERY,
            fetchPolicy: "network-only"
        });    
    
        const event = response.data[queryName];

        setEventName(event.eventName);
        setEventStreamURL(event.eventStreamURL ? event.eventStreamURL : "");
        setEventStreamMediaType(event.eventStreamMediaType ? event.eventStreamMediaType : "");
        setEventIngestStreamURL(event.eventIngestStreamURL ? event.eventIngestStreamURL : "");
        setEventIngestStreamKey(event.eventIngestStreamKey ? event.eventIngestStreamKey : "");
        setEventPrice(event.eventPrice ? event.eventPrice : "");
        setEventCurrency(event.eventCurrency ? event.eventCurrency : "");
        setEventStartDate(event.eventStartDate ? event.eventStartDate : "");
        setEventEndDate(event.eventEndDate ? event.eventEndDate : "");
        setClientURL(event.clientURL ? event.clientURL : "");
        setEmailSource(event.eventSourceEmail ? event.eventSourceEmail : "");
        setEmailSupport(event.eventSupportEmail ? event.eventSupportEmail : "");

        setView("edit");
        props.setEventsView("edit");
        props.setLoadingBar(false);
    }
    
    function customerCancelButton() {
        setCustomerView("create");
        clearUserForm();
    }

    // USERS
    async function customerFindButton() {
        // Retrieve list of users
        const user = await Auth.currentSession();

        const queryName = "userSearch";
        const QUERY = gql`
            query ${queryName}(
                $userId: String,
                $email: String
            ) {
                ${queryName} (
                    userId: $userId,
                    email: $email
                ) {
                    userId,
                    givenName,
                    familyName,
                    email,
                    phone
                }
            }
        `;

        const response: any = await Configuration.GraphQLClient(user).query({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                email: getCustomerEmail
            },
            query: QUERY,
            fetchPolicy: "network-only"
        });

        setUserSearchResults(response.data[queryName]);
    }

    async function getEventUsers(id?: string) {
        props.setLoadingBar(true);
        // Retrieve list of users
        const user = await Auth.currentSession();
        
        const queryName = "getEventUserList";
        const QUERY = gql`
            query ${queryName}(
                $userId: String,
                $eventId: String,
                $companyId: String
            ) {
                ${queryName} (
                    userId: $userId,
                    eventId: $eventId,
                    companyId: $companyId
                ) {
                    userId,
                    givenName,
                    familyName,
                    email,
                    phone,
                    auth,
                    emailSent,
                    emailSentCount
                }
            }
        `;
        const response: any = await Configuration.GraphQLClient(user).query({
            variables: {
                "userId": user.getAccessToken().payload.sub,
                eventId: id ? id : getEventId,
                companyId: props.companyId
            },
            query: QUERY,
            fetchPolicy: "network-only"
        });
        setEventUserList(response.data[queryName]);
        props.setLoadingBar(false);
    }

    // Show the users list and the user form
    async function showUsers(id: string, eventId: string, eventName: string) {
        setView("users");
        props.setEventsView("users");
        setCustomerView("create");
        setEventId(eventId);
        setEventName(eventName);
        // Retrieve list of users
        await getEventUsers(eventId);
    }

    function showEditCustomerForm(customer: any) {
        setCustomerId(customer.InternalID);
        setCustomerName(customer.CustomerName);
        setCustomerEmail(customer.CustomerEmail);
        setCustomerPhone(customer.CustomerPhone);
        setCustomerView("edit");
    }

    function userCSVTemplate() {
        let template = [
            [ "companyid", props.companyId ],
            [ "eventid", getEventId ],
            [ ],
            [ "givenname", "familyname", "email", "phone" ]
        ];

        for (const item of getEventUserList) {
            template.push([
                item.givenName,
                item.familyName,
                item.email,
                item.phone
            ]);
        }
        return template;
    }

    async function deleteCustomer(companyId: string, userId: string) {
        const user = await Auth.currentSession();
        
        const queryName = "removeUserFromEvent";
        const QUERY = gql`
            mutation ${queryName}(
                $userId: String, 
                $companyId: String,
                $eventId: String
            ) {
                ${queryName} (
                    userId: $userId,
                    companyId: $companyId,
                    eventId: $eventId
                )
            }
        `;

        await Configuration.GraphQLClient(user).mutate({
            variables: {
                userId,
                companyId,
                eventId: getEventId,
            },
            mutation: QUERY,
        });

        await getEventUsers();
    }

    const handleEventCurrencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEventCurrency(event.target.value as string);
    };

    function fileUploadAction() {
        fileUploadRef.current.click();
    }

    async function fileUploadInputChange() {
        props.setLoadingBar(true);
        // @ts-ignore
        const file = document.getElementById('filebutton').files[0];

        let companyId = "";
        let eventId = "";

        let chapter = "";

        let objectArray: any = [ ];

        await new Promise((resolve, reject) => {
            papa.parse(file, {
                step: function(result) {
                    if (chapter !== "data") {
                        for (const item of result.data) {
                            switch(item) {
                                case "companyid": {
                                    chapter = "companyid";
                                }
                                break;

                                case "eventid": {
                                    chapter = "eventid";
                                }
                                break;

                                case "givenname": {
                                    chapter = "data";
                                    break;
                                }
                                break;

                                default: {
                                    switch(chapter) {
                                        case "companyid": {
                                            companyId = item;
                                            chapter = "";
                                        }
                                        break;

                                        case "eventid": {
                                            eventId = item;
                                            chapter = "";
                                        }
                                        break;
                                    }
                                }
                            }
                        }
                    } else {
                        // DATA PARSE
                        const givenName = result.data[0];
                        const familyName = result.data[1];
                        const email = result.data[2];
                        const phone = result.data[3];

                        if (
                            givenName.length === 0 ||
                            email.length === 0 || typeof email === "undefined"
                        ) {

                        } else {
                            objectArray.push({
                                givenName,
                                familyName,
                                email,
                                phone
                            });
                        }
                    }
                },
                complete: function() {
                    console.log("Complete");
                    return resolve("done");
                }
            });
        });

        async function sendBatchToEndpoint(data) {
            const user = await Auth.currentSession();
            const queryName = "importEventUserList";
            const QUERY = gql`
                mutation ${queryName}(
                    $userId: String,
                    $eventId: String,
                    $data: String
                ) {
                    ${queryName} (
                        userId: $userId,
                        eventId: $eventId,
                        data: $data
                    )
                }
            `;
    
            await Configuration.GraphQLClient(user).mutate({
                variables: {
                    "userId": user.getAccessToken().payload.sub,
                    eventId: getEventId,
                    data: Buffer.from(JSON.stringify(data)).toString("base64")
                },
                mutation: QUERY,
            });
            return;
        }

        // Push to the backend
        async function recursiveProcessUsers(counter, dataArray, batchArray, callback) {

            if (counter >= dataArray.length) {

                if (dataArray.length < 25) {
                    await sendBatchToEndpoint(batchArray);
                }

                return callback("done");
            } else {

                if (counter > 0 && counter % 25 === 0) {
                    await sendBatchToEndpoint(batchArray);
                    batchArray = [ ];
                } else {
                    batchArray.push(dataArray[counter]);
                }
                
                counter += 1;
                return recursiveProcessUsers(counter, dataArray, batchArray, callback);
            }
        }

        let batchArray = [];
        let counter = 0;

        await new Promise((resolve, reject) => {
            recursiveProcessUsers(counter, objectArray, batchArray, function() {
                return resolve("done");
            });
        });

        // Clear the value so you can reimport
        // @ts-ignore
        document.getElementById('filebutton').value = null;

        await getEventUsers();
        props.setLoadingBar(false);
    }

    // Email the user their credentials
    async function emailCustomer(companyId: string, userId: string) {
        props.setLoadingBar(true);
        const user = await Auth.currentSession();
        const queryName = "emailUserCredentials";
        const QUERY = gql`
            mutation ${queryName}(
                $adminId: String,
                $userId: String, 
                $companyId: String,
                $eventId: String
            ) {
                ${queryName} (
                    adminId: $adminId,
                    userId: $userId,
                    companyId: $companyId,
                    eventId: $eventId
                )
            }
        `;

        await Configuration.GraphQLClient(user).mutate({
            variables: {
                "adminId": user.getAccessToken().payload.sub,
                userId,
                companyId,
                eventId: getEventId,
            },
            mutation: QUERY,
        });
        await getEventUsers();
        props.setLoadingBar(false);
    }

    let eventForm;

    function backToEventList() {
        clearForm();
        setView("");
    }

    switch(view) {
        case "edit": {
            eventForm = (<div className="content-create" ref={eventRef}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form className="" noValidate>
                        <h3>Edit Event</h3>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="eventname"
                            label="Event Name"
                            name="eventname"
                            value={eventName}
                            onChange={(e) => { setEventName(e.target.value); } }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="eventstreamurl"
                            label="Event Stream URL"
                            id="eventstreamurl"
                            value={eventStreamURL}
                            onChange={(e) => { setEventStreamURL(e.target.value); } }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="eventstreamfiletype"
                            label="Event Stream File Type"
                            id="eventstreamfiletype"
                            value={getEventStreamMediaType}
                            onChange={(e) => { setEventStreamMediaType(e.target.value); } }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="eventingeststreamurl"
                            label="Event Ingest Stream URL"
                            id="eventingeststreamurl"
                            value={getEventIngestStreamURL}
                            onChange={(e) => { setEventIngestStreamURL(e.target.value); } }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="eventingeststreamkey"
                            label="Event Ingest Stream Key"
                            id="eventingeststreamkey"
                            value={getEventIngestStreamKey}
                            onChange={(e) => { setEventIngestStreamKey(e.target.value); } }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="eventprice"
                            label="Event Price"
                            id="eventprice"
                            value={eventPrice}
                            onChange={(e) => { setEventPrice(e.target.value); } }
                        />
                        <InputLabel id="demo-simple-select-outlined-label" className="currency-label">Currency</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={eventCurrency}
                            onChange={handleEventCurrencyChange}
                            fullWidth
                            variant="outlined"
                            label="Currency"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"USD"}>USD</MenuItem>
                        </Select>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="clienturl"
                            label="Web Client URL"
                            id="clienturl"
                            value={clientURL}
                            onChange={(e) => { setClientURL(e.target.value); } }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="emailsource"
                            label="Event Source Email"
                            id="emailSource"
                            value={emailSource}
                            onChange={(e) => { setEmailSource(e.target.value); } }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="emailsupport"
                            label="Event Support Email"
                            id="emailsupport"
                            value={emailSupport}
                            onChange={(e) => { setEmailSupport(e.target.value); } }
                        />
                        <div className="form-row event-date">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd-MMM-yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Event Start Date"
                                value={eventStartDate}
                                onChange={setEventStartDate}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                            />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Time picker"
                                value={eventStartDate}
                                onChange={setEventStartDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </div>
                        <div className="form-row event-date">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd-MMM-yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Event End Date"
                                value={eventEndDate}
                                onChange={setEventEndDate}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                            />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Time picker"
                                value={eventEndDate}
                                onChange={setEventEndDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </div>
                        <div className="buttons-row">
                            <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                className="cancel-event-button"
                                onClick={cancelEditEvent}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className="new-event-button"
                                onClick={editEvent}
                            >
                                Update
                            </Button>
                        </div>
                    </form>
                </MuiPickersUtilsProvider>
            </div>);
        }
        break;

        case "users": {
            let customerCancel = (<></>);
            let fileName = "export-users-" + getEventId + ".csv";

            let backButton = (<Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className="create-new-company"
                onClick={backToEventList}
            >
                Back to Event List
            </Button>);

            eventForm = (
                <div className="content-create users">
                    {backButton}
                    <Divider />
                    <form className="" noValidate>
                        <h3>Export &amp; Import Event Users</h3>
                        <p>
                            When importing a CSV of users, the email field will be used to lookup and see if the user already exists within the system.
                        </p>
                        <div className="buttons-row">
                            {/* {customerCancel} */}
                            <CSVLink data={userCSVTemplate()} filename={fileName} target="_blank" >Export CSV Template</CSVLink>
                        </div>
                        <div className="buttons-row">
                            {customerCancel}
                            
                            <input id="filebutton" type="file" hidden ref={fileUploadRef} onChange={fileUploadInputChange} />
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className="new-event-button"
                                onClick={fileUploadAction}
                            >
                                Import CSV
                            </Button>
                        </div>
                    </form>
                </div>
            );

            return (
                <div className="content">
                    <div className="content-list">
                        <h3>{props.companyName} - {eventName} - User List</h3>
                        <TableContainer component={Paper}>
                            <Table className="event-user-list" aria-label="event user list table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Given Name</TableCell>
                                        <TableCell align="left">Family Name</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="left">Phone</TableCell>
                                        <TableCell align="left">Auth Code</TableCell>
                                        <TableCell align="left">Email Sent</TableCell>
                                        <TableCell align="left">Email Sent Count</TableCell>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        getEventUserList.map((row: any) => {
                                            let emailSent = (<></>);
                                            if (row.emailSent) {
                                                emailSent = (<FontAwesomeIcon icon={faCheck} />)
                                            }
                                            return (
                                                <TableRow key={row.userId}>
                                                    <TableCell className="company-list-item">
                                                        { row.givenName }
                                                    </TableCell>
                                                    <TableCell className="company-list-item">
                                                        { row.familyName }
                                                    </TableCell>
                                                    <TableCell className="company-list-item">
                                                        { row.email }
                                                    </TableCell>
                                                    <TableCell className="company-list-item">
                                                        { row.phone }
                                                    </TableCell>
                                                    <TableCell className="company-list-item copy" onClick={() => {
                                                        navigator.clipboard.writeText(row.auth)
                                                    }}>
                                                        <label>{ row.auth }</label>
                                                        <FontAwesomeIcon icon={faCopy} />
                                                    </TableCell>
                                                    <TableCell className="company-list-item" align="center">
                                                        { emailSent }
                                                    </TableCell>
                                                    <TableCell className="company-list-item" align="center">
                                                        { row.emailSentCount }
                                                    </TableCell>
                                                    <TableCell className="email-user" onClick={() => { emailCustomer(props.companyId, row.userId) }}>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </TableCell>
                                                    <TableCell onClick={() => { deleteCustomer(props.companyId, row.userId) }}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {eventForm}
                </div>
            )
        }

        default: {
            eventForm = (<div className="content-create" ref={eventRef}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form className="" noValidate>
                    <h3>Create New Event</h3>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="eventname"
                        label="Event Name"
                        name="eventname"
                        value={eventName}
                        onChange={(e) => { setEventName(e.target.value); } }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="eventstreamurl"
                        label="Event Stream URL"
                        id="eventstreamurl"
                        value={eventStreamURL}
                        onChange={(e) => { setEventStreamURL(e.target.value); } }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="eventstreamfiletype"
                        label="Event Stream File Type"
                        id="eventstreamfiletype"
                        value={getEventStreamMediaType}
                        onChange={(e) => { setEventStreamMediaType(e.target.value); } }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="eventingeststreamurl"
                        label="Event Ingest Stream URL"
                        id="eventingeststreamurl"
                        value={getEventIngestStreamURL}
                        onChange={(e) => { setEventIngestStreamURL(e.target.value); } }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="eventingeststreamkey"
                        label="Event Ingest Stream Key"
                        id="eventingeststreamkey"
                        value={getEventIngestStreamKey}
                        onChange={(e) => { setEventIngestStreamKey(e.target.value); } }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="eventprice"
                        label="Event Price"
                        id="eventprice"
                        value={eventPrice}
                        onChange={(e) => { setEventPrice(e.target.value); } }
                    />
                    <InputLabel id="demo-simple-select-outlined-label" className="currency-label">Currency</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={eventCurrency}
                        onChange={handleEventCurrencyChange}
                        fullWidth
                        variant="outlined"
                        label="Currency"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={"USD"}>USD</MenuItem>
                    </Select>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="clienturl"
                        label="Web Client URL"
                        id="clienturl"
                        value={clientURL}
                        onChange={(e) => { setClientURL(e.target.value); } }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="emailsource"
                        label="Event Source Email"
                        id="emailSource"
                        value={emailSource}
                        onChange={(e) => { setEmailSource(e.target.value); } }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="emailsupport"
                        label="Event Support Email"
                        id="emailsupport"
                        value={emailSupport}
                        onChange={(e) => { setEmailSupport(e.target.value); } }
                    />
                    <div className="form-row event-date">
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd-MMM-yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Event Start Date"
                            value={eventStartDate}
                            onChange={setEventStartDate}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                        <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Time picker"
                            value={eventStartDate}
                            onChange={setEventStartDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </div>
                    <div className="form-row event-date">
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd-MMM-yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Event End Date"
                            value={eventEndDate}
                            onChange={setEventEndDate}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                        <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Time picker"
                            value={eventEndDate}
                            onChange={setEventEndDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </div>
                    <div className="buttons-row">
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="new-event-button"
                            onClick={createNewEvent}
                        >
                            Create New Event
                        </Button>
                    </div>
                </form>
            </MuiPickersUtilsProvider>
            </div>);
        }
        break;
    }

    return (
        <div className="content">
            <div className="content-list">
                <div className="content-header-row">
                    <h3>{props.companyName} - Event List</h3>
                </div>
                <TableContainer component={Paper}>
                    <Table className="companies-table" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="center">Stream URL</TableCell>
                            <TableCell align="left">Price</TableCell>
                            <TableCell align="left">Currency</TableCell>
                            <TableCell align="left">Start Date</TableCell>
                            <TableCell align="left">End Date</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                eventList.map((row: any) => {
                                    const eStartDateTime = format(parseISO(row.eventStartDate), 'dd-MMM-yyyy p');
                                    const eEndDateTime = format(parseISO(row.eventEndDate), 'dd-MMM-yyyy p');
                                    
                                    let streamSet: any = "";
                                    if (row.eventStreamURL !== null && row.eventStreamURL.length > 0) {
                                        streamSet = <FontAwesomeIcon icon={faCheck} />
                                    }

                                    return (
                                        <TableRow key={row.eventId}>
                                            <TableCell className="company-list-item">
                                                { row.eventName }
                                            </TableCell>
                                            <TableCell className="company-list-item" align="center">
                                                { streamSet }
                                            </TableCell>
                                            <TableCell className="company-list-item">
                                                { row.eventPrice }
                                            </TableCell>
                                            <TableCell className="company-list-item">
                                                { row.eventCurrency }
                                            </TableCell>
                                            <TableCell className="company-list-item">
                                                { eStartDateTime }
                                            </TableCell>
                                            <TableCell className="company-list-item">
                                                { eEndDateTime }
                                            </TableCell>
                                            <TableCell onClick={() => { showUsers(row.companyId, row.eventId, row.eventName); }}>
                                                <FontAwesomeIcon className="icon-button" icon={faUserFriends} />
                                            </TableCell>
                                            <TableCell onClick={() => { editEventForm(props.companyId, row.eventId) }}>
                                                <FontAwesomeIcon className="icon-button" icon={faPencilAlt} />
                                            </TableCell>
                                            <TableCell onClick={() => { deleteEvent(props.companyId, row.eventId) }}>
                                                <FontAwesomeIcon className="icon-button" icon={faTrash} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {eventForm}
        </div>
    );       
}

export default Events;