import AWSAppSyncClient  from "aws-appsync";

export default class Configuration {
	public static getInstance() {
        if (!Configuration.instance) {
            Configuration.instance = new Configuration();
        }

        return Configuration.instance;
    }

    public static GraphQLClient(user) {
        if (typeof Configuration.client === "undefined") {
            Configuration.client = new AWSAppSyncClient({
                url: Configuration.getInstance().getBackendURL(),
                region: Configuration.getInstance().getRegion(),
                auth: {
                  type: "AMAZON_COGNITO_USER_POOLS",
                  jwtToken: user.getAccessToken().getJwtToken(),
                }
            });
        }
        return Configuration.client;
    }

    private static instance: Configuration;
    private static client;

    constructor() {}

    public getRegion() {
        return "us-east-1";
    }

	public getBackendURL() {
        return "https://exmxkh6ueje7thgk2p52e774ey.appsync-api.us-east-1.amazonaws.com/graphql";
    }
}