import React, { useRef, useState } from 'react';

import Button from '@material-ui/core/Button/Button';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import TextField from '@material-ui/core/TextField/TextField';
import Dialog from '@material-ui/core/Dialog';

import Amplify, { Auth } from 'aws-amplify';
import './signin.css';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import { Redirect } from 'react-router';

Amplify.configure({
    Auth: {
        region: "us-east-1",
        userPoolId: "us-east-1_fS3TPGEd3",
        userPoolWebClientId: "21vn7gtqv3j8k26g2cd71l4q7v"
    }
});

function Signin() {

    const [ getEmail, setEmail ] = useState("");
    const [ getPass, setPass ] = useState("");
    const [ getNewPassword, setNewPassword ] = useState("");
    const [ getUser, setUser ] = useState();

    const [ redirect, setRedirect ] = useState(false);

    const [openNewPasswordDialog, setNewPasswordDialog] = useState(false);

    // Refs
    const DialogRef = useRef(null);

    function setNewPasswordDialogOpen() {
        setNewPasswordDialog(true);
    }

    // New Password Path
    function handleNewPasswordDialogClose() {
        setNewPasswordDialog(false);
    }

    async function confirmNewPassword() {
        const response = await Auth.completeNewPassword(
            getUser,
            getNewPassword
        );

        // Check if it exists
        if (response.signInUserSession.accessToken.payload.sub) {
            // redirect
            setRedirect(true);
        }
    }

    // Process SignIn
    const processSignin = async () => {
        try {
            const user = await Auth.signIn(getEmail, getPass);            
            switch(user.challengeName) {
                case "NEW_PASSWORD_REQUIRED": {
                    setUser(user); // for use when confirming
                    setNewPasswordDialogOpen(); // open dialog
                }
                break;
                default:
                    if (user.signInUserSession.accessToken.payload.sub) {
                        // redirect
                        setRedirect(true);
                    }
                    break;
            }
        } catch(error) {
            console.log('Error signing in: ', error);
        }
    }

    if (redirect) {
        return (
            <Redirect to="/companies" />
        )
    } else {
        return (
            <div className="container">
                <CssBaseline />
                <div className="header-row">
                    <img className="logo" src="./MESLlogo.png"></img>
                </div>
                <div className="">
                    <form className="" noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={getEmail}
                            onChange={(e) => { setEmail(e.target.value); } }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={getPass}
                            onChange={(e) => { setPass(e.target.value); } }
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="signin-button"
                            onClick={processSignin}
                        >
                            Sign In
                        </Button>
                    </form>
                </div>
                <Dialog
                    open={openNewPasswordDialog}
                    fullWidth={true}
                    maxWidth="md"
                    onClose={handleNewPasswordDialogClose}
                    className="dialog-new-password"
                    ref={DialogRef}
                >
                    <DialogContent>
                        <DialogContentText>
                            You need to update your password. Please input your new password here
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="newpassword"
                            label="New Password"
                            type="password"
                            fullWidth
                            value={getNewPassword}
                            onChange={(e) => { setNewPassword(e.target.value); }}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="signin-button"
                            onClick={confirmNewPassword}
                        >
                            Update
                        </Button>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default Signin;